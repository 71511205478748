import {
  ArrowRightOutlined,
  DownloadOutlined,
  DownOutlined,
  FileDoneOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import { useCompany } from "@inspecto/common";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Space,
  Switch,
  TableColumnType,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuthentication } from "src/authentication";
import {
  MultiLineTag,
  RemoveButton,
  RestoreButton,
  StatusBadge,
} from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../api";
import {
  CompanyDepartmentSelectFilterItem,
  CustomVehicleFieldStatusBadge,
  DownloadVehicleQrCodesModal,
  FilterItem,
  FiltersRow,
  FilterTabsRow,
  VehicleGroupsFilterItem,
} from "../../components";
import { useCommonStateContext, VehiclesViewState } from "../../contexts";
import { useVehicleGroups, useVehicleTypes } from "../../hooks";
import { useRestoreDeletedVehicle } from "../../hooks/useRestoreDeletedVehicle";
import { ListVehicle } from "../../models";
import { BackOfficeListViewLayout } from "../BackOfficeListViewLayout";

export function VehiclesListView() {
  const company = useCompany();
  const { user } = useAuthentication();
  const { useVehicleFieldsInsteadOfLastProtocolAsVehicleStatus } = useCompany();
  const { t } = useTranslation("backoffice");
  const { t: globalTranslation } = useTranslation("translation");
  const history = useHistory();
  const [filtersForm] = Form.useForm();
  const [isDownloadQrCodesModalVisible, setIsDownloadQrCodesModalVisible] =
    useState(false);

  const { vehiclesView } = useCommonStateContext();

  const { restore } = useRestoreDeletedVehicle();
  const { vehicleGroups, vehicleGroupOptions } = useVehicleGroups();
  const { isLoadingVehicleTypes, vehicleTypeOptions } = useVehicleTypes();

  const removeVehicle = useCallback(
    async (vehicleId: string) => {
      try {
        await backOfficeApi.removeVehicle(vehicleId);
      } catch (e) {
        toast.error(globalTranslation("somethingWentWrong"));
        return;
      }

      filtersForm.submit();
      toast.success(t("vehiclesView.vehicleSuccessfullyDeleted"));
    },
    [filtersForm, globalTranslation, t]
  );

  const dataGetter = useMemo(() => {
    return async (vehiclesView: VehiclesViewState) => {
      return await backOfficeApi.getAllVehicles(vehiclesView);
    };
  }, []);

  const columns = useMemo<TableColumnType<ListVehicle>[]>(() => {
    return [
      {
        dataIndex: "plateNumber",
        title: t("vehiclesView.plateNumber"),
        width: 200,
        render: (plateNumber, vehicle) => {
          const foundVehicleType = vehicleTypeOptions.find(
            (vehicleTypeOption) =>
              vehicleTypeOption.value === vehicle.vehicleType
          );
          return (
            <div>
              <Typography.Text strong>{plateNumber}</Typography.Text>
              {foundVehicleType && (
                <div>
                  <Typography.Text type="secondary">
                    {foundVehicleType.label}
                  </Typography.Text>
                </div>
              )}
            </div>
          );
        },
      },
      {
        dataIndex: "vehicleGroups",
        title: t("vehiclesView.vehicleGroups"),
        width: "auto",
        render: (value) => {
          if (value) {
            return (
              <>
                {(value as string[]).map((vehicleGroup) => (
                  <MultiLineTag key={vehicleGroup} style={{ marginBottom: 4 }}>
                    {vehicleGroups.find((vg) => vg.id === vehicleGroup)?.label}
                  </MultiLineTag>
                ))}
              </>
            );
          } else {
            return <></>;
          }
        },
      },
      ...(company.allowManagingCompanyDepartments
        ? ([
            {
              dataIndex: "companyDepartmentObject",
              title: t("settings.companyDepartments.singleItemLabel"),
              width: "auto",
              render: (
                companyDepartmentObject: ListVehicle["companyDepartmentObject"]
              ) => <MultiLineTag>{companyDepartmentObject.name}</MultiLineTag>,
            },
          ] as TableColumnType<ListVehicle>[])
        : []),
      {
        dataIndex: "lastProtocolDate",
        title: t("vehiclesView.lastProtocolDate"),
        width: 220,
        render: (value) => {
          if (value) {
            return <>{dayjs(value as string).format("DD.MM.YYYY HH:mm")}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        title: useVehicleFieldsInsteadOfLastProtocolAsVehicleStatus
          ? t("vehiclesView.status")
          : t("vehiclesView.lastProtocolStatus"),
        width: 120,
        render: (value: ListVehicle) => {
          if (!value.isActive) {
            return (
              <StatusBadge
                statusText={t("vehiclesView.vehicleIsRemoved")}
                color="gray"
              />
            );
          }

          if (useVehicleFieldsInsteadOfLastProtocolAsVehicleStatus) {
            return <CustomVehicleFieldStatusBadge status={value.status} />;
          } else {
            return (
              <CustomVehicleFieldStatusBadge
                status={value.lastProtocolStatus || "none"}
              />
            );
          }
        },
      },
      {
        key: "action",
        width: 320,
        align: "right",
        render: (value: ListVehicle) => (
          <Space size="small">
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    icon: <FileDoneOutlined />,
                    onClick: () => {
                      history.push(
                        urls.backOffice.protocols({
                          selectedVehicle: {
                            value: value.id,
                            label: value.plateNumber,
                          },
                        })
                      );
                    },
                    label: t("protocolsView.pageTitle"),
                  },
                ],
              }}
            >
              <Button size="small">
                {t("tableActions.goTo")} <DownOutlined />
              </Button>
            </Dropdown>
            <Button
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                history.push(urls.backOffice.vehicleCard(value.id));
              }}
              icon={<ArrowRightOutlined />}
            >
              {t("vehicleCard")}
            </Button>
            {value.isActive ? (
              <RemoveButton
                confirmationTitle={t(
                  "vehiclesView.deleteVehicleConfirmationModal.title"
                )}
                onRemoveConfirm={async () => removeVehicle(value.id)}
              />
            ) : (
              <RestoreButton
                onRestoreConfirm={async () => {
                  await restore(value.id);
                  filtersForm.submit();
                }}
                confirmationTitle={t(
                  "vehiclesView.restoreVehicleConfirmationModal.title"
                )}
              />
            )}
          </Space>
        ),
      },
    ];
  }, [
    t,
    company.allowManagingCompanyDepartments,
    useVehicleFieldsInsteadOfLastProtocolAsVehicleStatus,
    vehicleTypeOptions,
    vehicleGroups,
    history,
    removeVehicle,
    restore,
    filtersForm,
  ]);

  const stickyTableActions = useMemo(
    () => [
      {
        buttonLabel: t("tableActions.downloadXLSX"),
        callback: async () => backOfficeApi.downloadVehiclesList(vehiclesView),
        icon: <DownloadOutlined />,
      },
      {
        buttonLabel: t("vehiclesView.downloadQRCodes"),
        callback: async () => setIsDownloadQrCodesModalVisible(true),
        icon: <QrcodeOutlined />,
      },
    ],
    [t, vehiclesView]
  );

  return (
    <>
      <BackOfficeListViewLayout
        isLoading={isLoadingVehicleTypes}
        sortedTableProps={["plateNumber", "lastProtocolDate"]}
        dataGetter={dataGetter}
        columns={columns}
        commonStateViewName="vehiclesView"
        debouncedFilterProps={["plateNumber"]}
        pageTitle={t("vehiclesView.pageTitle")}
        createButtonAction={() => history.push(urls.backOffice.createVehicle())}
        filtersForm={filtersForm}
        stickyTableActions={stickyTableActions}
        filters={
          <>
            <FiltersRow>
              <FilterItem
                label={t("includeArchivedItems")}
                valuePropName="checked"
                name="includeArchived"
              >
                <Switch />
              </FilterItem>
              <FilterItem
                label={t("vehiclesView.issuesOnly")}
                valuePropName="checked"
                name="issuesOnly"
              >
                <Switch />
              </FilterItem>
            </FiltersRow>
            <FiltersRow>
              {company.allowManagingCompanyDepartments && user && (
                <CompanyDepartmentSelectFilterItem
                  options={user.allowedCompanyDepartments.map(
                    (companyDepartment) => ({
                      label: companyDepartment.name,
                      value: companyDepartment.id,
                    })
                  )}
                />
              )}
              <VehicleGroupsFilterItem options={vehicleGroupOptions} />
              <FilterItem name="plateNumber">
                <Input.Search placeholder={t("search")} />
              </FilterItem>
            </FiltersRow>

            <FilterTabsRow
              name="selectedVehicleType"
              options={vehicleTypeOptions}
            />
          </>
        }
      />
      {isDownloadQrCodesModalVisible && (
        <DownloadVehicleQrCodesModal
          vehiclesView={vehiclesView}
          closeModal={() => setIsDownloadQrCodesModalVisible(false)}
        />
      )}
    </>
  );
}
