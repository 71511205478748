import React, {
  useCallback,
  useContext,
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
} from "react";
import { Workbox } from "workbox-window";

import { ServiceWorkerMessage } from "src/types";

const serviceWorkerContext = createContext<{
  serviceWorker: Workbox | null;
  sendServiceWorkerMessage: (message: ServiceWorkerMessage) => void;
}>({
  serviceWorker: null,
  sendServiceWorkerMessage: (m: ServiceWorkerMessage) => {},
});

const { Provider } = serviceWorkerContext;

export function ServiceWorkerProvider({
  isEnabled,
  children,
}: PropsWithChildren<{ isEnabled: boolean }>) {
  const wb = useMemo(
    () =>
      isEnabled && "serviceWorker" in navigator
        ? new Workbox("/service-worker.js")
        : null,
    [isEnabled]
  );

  useEffect(() => {
    (async () => {
      if (wb) {
        try {
          await wb.register();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("ServiceWorker registration failed:", error);
        }
      }
    })();
  }, [wb]);

  const sendServiceWorkerMessage = useCallback(
    async (message: ServiceWorkerMessage) => {
      if (!wb) return;
      try {
        await wb.messageSW(message);
      } catch (error: any) {
        if (
          error.message &&
          error.message.includes("ServiceWorker is in redundant state")
        ) {
          // eslint-disable-next-line no-console
          console.warn(
            "Attempted to message a redundant service worker:",
            error
          );
        } else {
          throw error;
        }
      }
    },
    [wb]
  );

  return (
    <Provider
      value={{
        serviceWorker: wb,
        sendServiceWorkerMessage,
      }}
    >
      {children}
    </Provider>
  );
}

export function useServiceWorker() {
  return useContext(serviceWorkerContext);
}
