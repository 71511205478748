import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { urls } from "src/urls";

import { FiltersRow } from "../../components";
import { BackOfficeLayout } from "../BackOfficeLayout";
import { damagesContentMinWidth, DamagesList } from "../DamagesList";

export function DamagesListView() {
  const { t } = useTranslation("backoffice");
  const history = useHistory();

  return (
    <BackOfficeLayout
      pageTitle={t("damages.pageTitle")}
      contentMinWidth={damagesContentMinWidth}
      createButtonAction={() => history.push(urls.backOffice.damages.create())}
    >
      <BackOfficeLayout.Content>
        <DamagesList>
          {({
            listElement,
            statusFiltersElement,
            selectedVehicleElement,
            selectedEmployeeElement,
            customVehicleFieldElement,
            vehicleTypeElement,
            vehicleGroupsElement,
            companyDepartmentsElement,
          }) => (
            <>
              <BackOfficeLayout.Filters>
                <FiltersRow>
                  {statusFiltersElement}
                  {selectedVehicleElement}
                  {selectedEmployeeElement}
                </FiltersRow>
                <FiltersRow>
                  {vehicleTypeElement}
                  {vehicleGroupsElement}
                  {customVehicleFieldElement}
                </FiltersRow>
                {companyDepartmentsElement && (
                  <FiltersRow>{companyDepartmentsElement}</FiltersRow>
                )}
              </BackOfficeLayout.Filters>
              {listElement}
            </>
          )}
        </DamagesList>
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
