import {
  ArrowRightOutlined,
  CopyOutlined,
  SwapOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { fetchAllPaginatedElements } from "@inspecto/common";
import {
  Button,
  Form,
  Space,
  Switch,
  TableColumnType,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { MultiSelect, RemoveButton, RestoreButton } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../api";
import {
  FilterItem,
  FiltersRow,
  ReorderListItemsModal,
} from "../../components";
import { CommonViewState } from "../../contexts";
import { useTemplateTagsRequirementsByVehicleType } from "../../hooks";
import { useRestoreItem } from "../../hooks/useRestoreDeletedItem";
import { useTemplateTags } from "../../hooks/useTemplateTags";
import { ListRetrieveTemplateTag, ListTemplate } from "../../models";
import { BackOfficeListViewLayout } from "../BackOfficeListViewLayout";
import { getTemplateTagsRequiredForTemplateExplanation } from "./utils/templateTagsRequirement";

export function TemplatesListView() {
  const history = useHistory();
  const { t } = useTranslation("backoffice");
  const [filtersForm] = Form.useForm();
  const [allTemplates, setAllTemplates] = useState<ListTemplate[]>([]);

  const { restore } = useRestoreItem({
    updateItem: async (id: string, data: { isActive: boolean }) =>
      backOfficeApi.updateTemplate(id, data),
  });
  const { templateTagOptions, isLoadingTemplateTags } = useTemplateTags();
  const [templateTagsRequirementsByVehicleType] =
    useTemplateTagsRequirementsByVehicleType();

  const dataGetter = useCallback(
    (templatesView: CommonViewState["templatesView"]) =>
      backOfficeApi.getTemplates(templatesView),
    []
  );

  const columns = useMemo<TableColumnType<ListTemplate>[]>(
    () => [
      {
        dataIndex: "label",
        title: t("templatesListView.templateLabel"),
        width: "auto",
      },
      {
        dataIndex: "vehicleTypes",
        title: t("settings.customVehicleFields.vehicleTypes"),
        width: 250,
        render: (value: ListTemplate["vehicleTypes"]) =>
          value.map((vehicleType) => (
            <Tag key={vehicleType.id}>{vehicleType.label}</Tag>
          )),
      },
      {
        title: t("templatesListView.templateTags"),
        width: 250,
        render: (template: ListTemplate) => {
          if (template.templateTags.length) {
            return template.templateTags.map((templateTag) => (
              <Tag key={templateTag.id}>{templateTag.label}</Tag>
            ));
          }

          const templateTagsRequiredForTemplateExplanation =
            getTemplateTagsRequiredForTemplateExplanation(
              t,
              templateTagsRequirementsByVehicleType,
              template.vehicleTypes
            );

          if (templateTagsRequiredForTemplateExplanation) {
            return (
              <Tooltip
                title={
                  templateTagsRequiredForTemplateExplanation.templateTagsRequiredForTemplateExplanation
                }
                style={{
                  maxWidth: 200,
                }}
              >
                <Typography.Text type="danger">
                  <Space>
                    <WarningOutlined />
                    {
                      templateTagsRequiredForTemplateExplanation.tagsAreRequiredText
                    }
                  </Space>
                </Typography.Text>
              </Tooltip>
            );
          }
        },
      },
      {
        align: "right",
        width: "320px",
        render: (value: ListTemplate) => (
          <Space size="small">
            <Button
              size="small"
              onClick={() => {
                history.push(urls.backOffice.templateBuilderEdit(value.id));
              }}
              icon={<ArrowRightOutlined />}
            >
              {t("tableActions.edit")}
            </Button>
            <Button
              size="small"
              onClick={() => {
                history.push(urls.backOffice.templateBuilderClone(value.id));
              }}
              icon={<CopyOutlined />}
            >
              {t("tableActions.clone")}
            </Button>
            {value.isActive ? (
              <RemoveButton
                confirmationTitle={t("builder.confirmations.template.delete")}
                onRemoveConfirm={async () => {
                  await backOfficeApi.removeTemplate(value.id);
                  filtersForm.submit();
                }}
              />
            ) : (
              <RestoreButton
                onRestoreConfirm={async () => {
                  await restore(value.id);
                  filtersForm.submit();
                }}
                confirmationTitle={t("restoreItemConfirmationModal.title")}
              />
            )}
          </Space>
        ),
      },
    ],
    [filtersForm, history, restore, t, templateTagsRequirementsByVehicleType]
  );

  return (
    <BackOfficeListViewLayout
      isLoading={isLoadingTemplateTags}
      dataGetter={dataGetter}
      columns={columns}
      filtersForm={filtersForm}
      commonStateViewName="templatesView"
      pageTitle={t("builder.templates")}
      createButtonAction={() =>
        history.push(urls.backOffice.templateBuilderEdit("new"))
      }
      stickyTableActions={[
        {
          buttonLabel: t("builder.reorderTemplates"),
          callback: async () => {
            const templates = await fetchAllPaginatedElements((pageNumber) =>
              backOfficeApi.getTemplates({
                tableParams: {
                  pageNumber,
                  sortByField: "",
                },
              })
            );
            setAllTemplates(templates);
          },
          icon: <SwapOutlined />,
        },
      ]}
      filters={
        <>
          <FiltersRow>
            <FilterItem
              label={t("includeArchivedItems")}
              valuePropName="checked"
              name="includeArchived"
            >
              <Switch />
            </FilterItem>
          </FiltersRow>
          <FiltersRow>
            <FilterItem name="templateTags" $inputWidth="medium">
              <MultiSelect<ListRetrieveTemplateTag["id"]>
                style={{ width: 350 }}
                options={templateTagOptions}
                placeholder={t("settings.templateTags.label")}
                noMargin
              />
            </FilterItem>
          </FiltersRow>
        </>
      }
      additionalRenderChildren={
        <>
          {allTemplates.length > 0 && (
            <ReorderListItemsModal
              groups={{
                "group-id": {
                  groupId: "group-id",
                  items: allTemplates,
                  groupHeader: t("builder.templateLabel"),
                  order: 0,
                },
              }}
              modalTitle={t("builder.reorderTemplates")}
              save={async (items) => {
                await backOfficeApi.reorderTemplates(
                  items.map((item) => item.id)
                );
              }}
              closeModal={() => setAllTemplates([])}
              onSuccess={() => {
                filtersForm.submit();
              }}
            />
          )}
        </>
      }
    />
  );
}
