import { ArrowRightOutlined, SwapOutlined } from "@ant-design/icons";
import { fetchAllPaginatedElements } from "@inspecto/common";
import { Button, Form, Space, TableColumnType } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { RemoveButton } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { ReorderListItem, ReorderListItemsModal } from "../../../components";
import { CommonViewState } from "../../../contexts";
import { ListRetrieveTemplateTag } from "../../../models";
import { BackOfficeListViewLayout } from "../../BackOfficeListViewLayout";

export function TemplateTagsListView() {
  const { t } = useTranslation("backoffice");
  const history = useHistory();
  const [filtersForm] = Form.useForm();

  const [allTemplateTags, setAllTemplateTags] = useState<ReorderListItem[]>([]);

  const dataGetter = useCallback(
    (filters: CommonViewState["templateTagsView"]) =>
      backOfficeApi.getTemplateTags(filters),
    []
  );

  const columns: TableColumnType<ListRetrieveTemplateTag>[] = [
    {
      title: t("settings.genericTags.fields.label"),
      dataIndex: "label",
    },
    {
      key: "action",
      width: "200px",
      align: "right",
      render: (value) => (
        <Space size="small">
          <Button
            size="small"
            onClick={() => {
              history.push(urls.backOffice.settings.editTemplateTag(value.id));
            }}
            icon={<ArrowRightOutlined />}
          >
            {t("tableActions.edit")}
          </Button>
          <RemoveButton
            onRemoveConfirm={async () => {
              await backOfficeApi.removeTemplateTag(value.id);
              filtersForm.submit();
            }}
            confirmationTitle={t("settings.genericTags.deleteConfirmation")}
          />
        </Space>
      ),
    },
  ];

  return (
    <BackOfficeListViewLayout
      commonStateViewName="templateTagsView"
      filtersForm={filtersForm}
      dataGetter={dataGetter}
      columns={columns}
      breadcrumbs={[{ label: t("settings.label") }]}
      pageTitle={t("settings.templateTags.label")}
      createButtonAction={() =>
        history.push(urls.backOffice.settings.createTemplateTag())
      }
      stickyTableActions={[
        {
          buttonLabel: t("reorderListItems.title"),
          callback: async () => {
            const templateTags = await fetchAllPaginatedElements((pageNumber) =>
              backOfficeApi.getTemplateTags({
                tableParams: { pageNumber },
              })
            );
            setAllTemplateTags(
              templateTags.map((group) => ({
                id: group.id,
                label: group.label,
              }))
            );
          },
          icon: <SwapOutlined />,
        },
      ]}
      additionalRenderChildren={
        <>
          {allTemplateTags.length > 0 && (
            <ReorderListItemsModal
              groups={{
                "group-id": {
                  groupId: "group-id",
                  items: allTemplateTags,
                  groupHeader: t("settings.genericTags.fields.label"),
                  order: 0,
                },
              }}
              closeModal={() => setAllTemplateTags([])}
              onSuccess={() => {
                filtersForm.submit();
              }}
              save={async (items) => {
                await backOfficeApi.reorderTemplateTags(
                  items.map((item) => item.id)
                );
              }}
              modalTitle={t("reorderListItems.title")}
            />
          )}
        </>
      }
    />
  );
}
