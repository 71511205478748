import { ArrowRightOutlined, SwapOutlined } from "@ant-design/icons";
import { fetchAllPaginatedElements } from "@inspecto/common";
import { Button, Form, Space, TableColumnType } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { RemoveButton } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { ReorderListItem, ReorderListItemsModal } from "../../../components";
import { CommonViewState } from "../../../contexts";
import { ListRetrieveCustomVehicleFieldsGroup } from "../../../models";
import { BackOfficeListViewLayout } from "../../BackOfficeListViewLayout";

export function CustomVehicleFieldsGroupsListView() {
  const { t } = useTranslation("backoffice");
  const history = useHistory();
  const [filtersForm] = Form.useForm();

  const [allCustomVehicleFieldsGroups, setAllCustomVehicleFieldsGroups] =
    useState<ReorderListItem[]>([]);

  const dataGetter = useCallback(
    (filters: CommonViewState["customVehicleFieldsGroupsView"]) =>
      backOfficeApi.getCustomVehicleFieldsGroups(filters),
    []
  );

  const columns: TableColumnType<ListRetrieveCustomVehicleFieldsGroup>[] = [
    {
      title: t("settings.genericGroups.fields.label"),
      dataIndex: "label",
    },
    {
      key: "action",
      width: "200px",
      align: "right",
      render: (value) => (
        <Space size="small">
          <Button
            size="small"
            onClick={() => {
              history.push(
                urls.backOffice.settings.editCustomVehicleFieldsGroup(value.id)
              );
            }}
            icon={<ArrowRightOutlined />}
          >
            {t("tableActions.edit")}
          </Button>
          <RemoveButton
            onRemoveConfirm={async () => {
              await backOfficeApi.removeCustomVehicleFieldsGroup(value.id);
              filtersForm.submit();
            }}
            confirmationTitle={t("settings.genericGroups.deleteConfirmation")}
          />
        </Space>
      ),
    },
  ];

  return (
    <BackOfficeListViewLayout
      commonStateViewName="customVehicleFieldsGroupsView"
      filtersForm={filtersForm}
      dataGetter={dataGetter}
      columns={columns}
      breadcrumbs={[{ label: t("settings.label") }]}
      pageTitle={t("settings.customVehicleFieldsGroups.label")}
      createButtonAction={() =>
        history.push(urls.backOffice.settings.createCustomVehicleFieldsGroup())
      }
      stickyTableActions={[
        {
          buttonLabel: t("reorderListItems.title"),
          callback: async () => {
            const customVehicleFieldsGroups = await fetchAllPaginatedElements(
              (pageNumber) =>
                backOfficeApi.getCustomVehicleFieldsGroups({
                  tableParams: { pageNumber },
                })
            );
            setAllCustomVehicleFieldsGroups(
              customVehicleFieldsGroups.map((group) => ({
                id: group.id,
                label: group.label,
              }))
            );
          },
          icon: <SwapOutlined />,
        },
      ]}
      additionalRenderChildren={
        <>
          {allCustomVehicleFieldsGroups.length > 0 && (
            <ReorderListItemsModal
              groups={{
                "group-id": {
                  groupId: "group-id",
                  items: allCustomVehicleFieldsGroups,
                  groupHeader: t("settings.genericGroups.fields.label"),
                  order: 0,
                },
              }}
              closeModal={() => setAllCustomVehicleFieldsGroups([])}
              onSuccess={() => {
                filtersForm.submit();
              }}
              save={async (items) => {
                await backOfficeApi.reorderCustomVehicleFieldsGroups(
                  items.map((item) => item.id)
                );
              }}
              modalTitle={t("reorderListItems.title")}
            />
          )}
        </>
      }
    />
  );
}
