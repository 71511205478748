import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Form, Space, TableColumnType } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { RemoveButton } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { CommonViewState } from "../../../contexts";
import { SimpleVehicleGroup } from "../../../models";
import { BackOfficeListViewLayout } from "../../BackOfficeListViewLayout";

export function VehicleGroupsListView() {
  const { t } = useTranslation("backoffice");
  const history = useHistory();
  const [filtersForm] = Form.useForm();

  const dataGetter = useCallback(
    ({ tableParams: { pageNumber } }: CommonViewState["vehicleGroupsView"]) =>
      backOfficeApi.getVehicleGroups(pageNumber),
    []
  );

  const columns: TableColumnType<SimpleVehicleGroup>[] = [
    { title: t("settings.genericGroups.fields.label"), dataIndex: "label" },
    {
      key: "action",
      width: "100px",
      render: (value) => (
        <Space size="small">
          <Button
            size="small"
            onClick={() => {
              history.push(urls.backOffice.settings.editVehicleGroup(value.id));
            }}
            icon={<ArrowRightOutlined />}
          >
            {t("tableActions.edit")}
          </Button>
          <RemoveButton
            onRemoveConfirm={async () => {
              await backOfficeApi.removeVehicleGroup(value.id);
              filtersForm.submit();
            }}
            confirmationTitle={t("settings.genericGroups.deleteConfirmation")}
          />
        </Space>
      ),
    },
  ];

  return (
    <BackOfficeListViewLayout
      commonStateViewName="vehicleGroupsView"
      filtersForm={filtersForm}
      dataGetter={dataGetter}
      columns={columns}
      breadcrumbs={[{ label: t("settings.label") }]}
      pageTitle={t("settings.vehicleGroups.label")}
      createButtonAction={() =>
        history.push(urls.backOffice.settings.createVehicleGroup())
      }
    />
  );
}
