import { Input, Select, TableColumnType } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { customFieldTypes } from "src/company";
import { MultiSelect } from "src/components";

import { backOfficeApi, GetCustomVehicleFieldsFilters } from "../api";
import { FilterItem, FiltersRow, VehicleGroupsFilterItem } from "../components";
import { CommonViewState } from "../contexts";
import {
  useCustomVehicleFieldsGroups,
  useVehicleGroups,
  useVehicleTypes,
} from "../hooks";
import { ListCustomVehicleField, VehicleType } from "../models";
import {
  BackOfficeListViewLayout,
  BackOfficeListViewLayoutProps,
} from "./BackOfficeListViewLayout";

interface Props<
  TCommonViewStateName extends keyof Pick<
    CommonViewState,
    "customVehicleFieldsView" | "fleetStateCustomVehicleFieldsListView"
  >
> extends Pick<
    BackOfficeListViewLayoutProps<ListCustomVehicleField, TCommonViewStateName>,
    | "createButtonAction"
    | "filtersForm"
    | "sortedTableProps"
    | "breadcrumbs"
    | "pageTitle"
    | "additionalRenderChildren"
    | "stickyTableActions"
  > {
  dataGetterExtraFilters?: Partial<GetCustomVehicleFieldsFilters>;
  isVehicleGroupsFilterVisible?: boolean;
  commonStateViewName: TCommonViewStateName;
  columns: TableColumnType<ListCustomVehicleField>[];
}

export function BaseCustomVehicleFieldsListView<
  TCommonViewStateName extends keyof Pick<
    CommonViewState,
    "customVehicleFieldsView" | "fleetStateCustomVehicleFieldsListView"
  >
>({
  isVehicleGroupsFilterVisible = false,
  ...props
}: Props<TCommonViewStateName>): JSX.Element {
  const { t } = useTranslation("backoffice");
  const { vehicleTypeOptions, isLoadingVehicleTypes } = useVehicleTypes();
  const { vehicleGroupOptions, isLoadingVehicleGroups } = useVehicleGroups();
  const {
    customVehicleFieldsGroupOptions,
    isLoadingCustomVehicleFieldsGroups,
  } = useCustomVehicleFieldsGroups();

  const dataGetter = useCallback(
    async (filters: CommonViewState[TCommonViewStateName]) =>
      await backOfficeApi.getCustomVehicleFields({
        ...filters,
        ...props.dataGetterExtraFilters,
      }),
    [props.dataGetterExtraFilters]
  );

  return (
    <BackOfficeListViewLayout<ListCustomVehicleField, TCommonViewStateName>
      isLoading={
        isLoadingCustomVehicleFieldsGroups ||
        isLoadingVehicleGroups ||
        isLoadingVehicleTypes
      }
      sortedTableProps={props.sortedTableProps}
      commonStateViewName={props.commonStateViewName}
      debouncedFilterProps={["label"]}
      filtersForm={props.filtersForm}
      dataGetter={dataGetter}
      columns={props.columns}
      breadcrumbs={props.breadcrumbs}
      pageTitle={props.pageTitle}
      filters={
        <>
          <FiltersRow>
            <FilterItem name="label" $inputWidth="medium">
              <Input.Search placeholder={t("search")} />
            </FilterItem>
            <FilterItem name="vehicleTypes">
              <MultiSelect<VehicleType["id"]>
                options={vehicleTypeOptions}
                style={{ width: 250 }}
                noMargin
                placeholder={t("settings.customVehicleFields.vehicleTypes")}
              />
            </FilterItem>
            {isVehicleGroupsFilterVisible && (
              <VehicleGroupsFilterItem
                isExcludeVehicleGroupsCheckboxVisible={false}
                options={vehicleGroupOptions}
              />
            )}
          </FiltersRow>
          <FiltersRow>
            <FilterItem name="customVehicleFieldsGroups">
              <MultiSelect<string>
                options={customVehicleFieldsGroupOptions}
                style={{ width: 450 }}
                noMargin
                placeholder={t("settings.customVehicleFieldsGroups.label")}
              />
            </FilterItem>
            <FilterItem name="type">
              <Select
                allowClear
                options={customFieldTypes.map((fieldType) => ({
                  label: t(`fieldType.${fieldType}`),
                  value: fieldType,
                }))}
                style={{ width: 200 }}
                placeholder={t("settings.customVehicleFields.fields.type")}
              />
            </FilterItem>
          </FiltersRow>
        </>
      }
      createButtonAction={props.createButtonAction}
      stickyTableActions={props.stickyTableActions}
      additionalRenderChildren={props.additionalRenderChildren}
    />
  );
}
