import {
  ApiError,
  useAllPaginatedElements,
  useCompany,
} from "@inspecto/common";
import { Alert, Form } from "antd";
import produce from "immer";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { Prompt, Redirect, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as uuid from "uuid";

import { MultiLanguageProvider } from "src/company";
import { CommonForm, Spinner } from "src/components";
import { emptyTranslatedField } from "src/locales";
import { urls } from "src/urls";
import {
  disableReloadPageConfirmation,
  enableReloadPageConfirmation,
} from "src/utils";

import { backOfficeApi } from "../../api";
import {
  ListCustomVehicleField,
  Page,
  SignaturePage,
  Template,
} from "../../models";
import { ListLinkType } from "../../models/LinkType";
import { BackOfficeLayout } from "../BackOfficeLayout";
import { StickySaveButtons } from "../vehicles/components/StickySaveButtons";
import { TemplateEditor } from "./components/TemplateEditor";
import { cloneTemplate } from "./utils";

const initialState = {
  customVehicleFields: [] as ListCustomVehicleField[],
  linkTypes: [] as ListLinkType[],
  isLoadingCustomVehicleFields: true,
  isLoadingLinkTypes: true,
  reorderSignaturePage: (fromIndex: number, toIndex: number) => {},
  createSignaturePage: (signaturePage: SignaturePage) => {},
  removeSignaturePage: (indexToRemove: number) => {},
  reorderQuestion: (
    fromPage: string,
    toPage: string,
    fromIndex: number,
    toIndex: number
  ) => {},
  setOnChoicesReorderCallback: (
    callback: ((sourceIndex: number, destinationIndex: number) => void) | null
  ) => {},
  form: {
    getFieldValue: (name: string) => undefined as any,
  },
  refreshCustomVehicleFields: () => {},
};

export type TemplateContext = typeof initialState;

export const templateContext = createContext<TemplateContext>(initialState);
export function useTemplateContext() {
  return useContext(templateContext);
}
const { Provider } = templateContext;

export function TemplateEditorView({
  isCloning = false,
}: {
  isCloning?: boolean;
}) {
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation("backoffice");
  const { t: tTranslation } = useTranslation();
  const { templateId } = useParams<{ templateId?: string }>();

  const history = useHistory();
  const company = useCompany();

  const [template, setTemplate] = useState<Template | null>(null);
  const [onChoicesReorderCallback, _setOnChoicesReorderCallback] = useState<
    ((sourceIndex: number, destinationIndex: number) => void) | null
  >(null);
  const [shouldShowExitWarning, setShouldShowExitWarning] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [form] = Form.useForm();
  const [
    customVehicleFields,
    isLoadingCustomVehicleFields,
    fetchAllCustomVehicleFields,
  ] = useAllPaginatedElements(
    useCallback(
      (pageNumber: number) =>
        backOfficeApi.getCustomVehicleFields({ tableParams: { pageNumber } }),
      []
    )
  );

  const [linkTypes, isLoadingLinkTypes] = useAllPaginatedElements(
    useCallback(
      (pageNumber: number) =>
        company.allowLinkingVehicles
          ? backOfficeApi.linkTypes.getList(pageNumber)
          : Promise.resolve({
              count: 0,
              results: [],
              next: null,
              previous: null,
            }),
      [company.allowLinkingVehicles]
    )
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (templateId) {
        try {
          let template = await backOfficeApi.getTemplate(templateId);

          if (isCloning) {
            template = cloneTemplate(template, t("copyOf"));
          }

          setTemplate(template);
        } catch (e) {
          if (e instanceof ApiError) {
            toast.error(t("builder.somethingWentWrong"));
            history.push(urls.backOffice.templateBuilder());
          } else {
            throw e;
          }
        } finally {
          setIsLoading(false);
        }
      } else {
        setTemplate({
          id: null,
          isActive: true,
          frontendId: uuid.v4(),
          vehicleType: [],
          templateTags: [],
          vehicleGroups: [],
          userRoles: [],
          pages: [],
          signaturePages: [],
          label: emptyTranslatedField,
          imageQuality: "standard",
          onProtocolCreation: "",
        });
        setIsLoading(false);
      }
    })();
  }, [t, history, templateId, isCloning]);

  const commonReorderPage = useCallback(
    (
      pageName: "pages" | "signaturePages",
      fromIndex: number,
      toIndex: number
    ) => {
      const currentPagesValue: (Page | SignaturePage)[] =
        form.getFieldValue(pageName);
      form.setFieldsValue({
        [pageName]: produce(currentPagesValue, (draft) => {
          const movingPage = draft[fromIndex];
          if (!movingPage) {
            return;
          }
          draft.splice(fromIndex, 1);
          draft.splice(toIndex, 0, movingPage);
        }),
      });
    },
    [form]
  );

  const reorderPage = useCallback(
    (fromIndex: number, toIndex: number) => {
      commonReorderPage("pages", fromIndex, toIndex);
    },
    [commonReorderPage]
  );

  const reorderSignaturePage = useCallback(
    (fromIndex: number, toIndex: number) => {
      commonReorderPage("signaturePages", fromIndex, toIndex);
    },
    [commonReorderPage]
  );

  const createSignaturePage = useCallback(
    (signaturePage: SignaturePage) => {
      const currentPagesValue: SignaturePage[] =
        form.getFieldValue("signaturePages");
      form.setFieldsValue({
        signaturePages: produce(currentPagesValue, (draft) => {
          draft.push(signaturePage);
        }),
      });
    },
    [form]
  );

  const removeSignaturePage = useCallback(
    (indexToRemove: number) => {
      const currentPagesValue: SignaturePage[] =
        form.getFieldValue("signaturePages");
      form.setFieldsValue({
        signaturePages: produce(currentPagesValue, (draft) => {
          draft.splice(indexToRemove, 1);
        }),
      });
    },
    [form]
  );

  const reorderQuestion: TemplateContext["reorderQuestion"] = useCallback(
    (fromPageId, toPageId, fromIndex, toIndex) => {
      const currentPagesValue: Page[] = form.getFieldValue("pages");
      form.setFieldsValue({
        pages: produce(currentPagesValue, (draft) => {
          const fromPage = Array.from(draft).find(
            (page) => page.frontendId === fromPageId
          );
          if (!fromPage) {
            throw new Error("Could not find fromPage during move");
          }
          const toPage =
            fromPageId === toPageId
              ? fromPage
              : Array.from(draft).find((page) => page.frontendId === toPageId);
          if (!toPage) {
            throw new Error("Could not find toPage during move");
          }

          const movingQuestion = fromPage.questions[fromIndex];

          if (!movingQuestion) {
            return;
          }

          fromPage.questions.splice(fromIndex, 1);
          toPage.questions.splice(toIndex, 0, movingQuestion);
        }),
      });
    },
    [form]
  );

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      const { destination, source, type } = result;
      if (!destination || !template) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      setShouldShowExitWarning(true);
      switch (type) {
        case "pages":
          reorderPage(source.index, destination.index);
          break;
        case "signaturePages":
          reorderSignaturePage(source.index, destination.index);
          break;
        case "QUESTION":
          reorderQuestion(
            source.droppableId,
            destination.droppableId,
            source.index,
            destination.index
          );
          break;
        case "CHOICE":
          onChoicesReorderCallback?.(source.index, destination.index);
          break;
      }
    },

    [
      template,
      reorderPage,
      reorderSignaturePage,
      reorderQuestion,
      onChoicesReorderCallback,
    ]
  );

  const save = useCallback(
    async (template: Template): Promise<{ templateId: string }> => {
      try {
        setShouldShowExitWarning(false);
        const response = await backOfficeApi.saveTemplate(template);
        toast.success(t("builder.templateSavedCorrectly"));
        return response;
      } catch (e) {
        if (e instanceof ApiError) {
          toast.error(t("builder.somethingWentWrong"));
          throw e;
        } else {
          throw e;
        }
      }
    },
    [t]
  );

  const initialValues = useMemo(
    () =>
      template || { id: null, frontendId: uuid.v4(), onProtocolCreation: "" },
    [template]
  );

  useEffect(() => form.setFieldsValue(initialValues), [form, initialValues]);

  const setOnChoicesReorderCallback = useCallback(
    (callback) => _setOnChoicesReorderCallback(() => callback),
    [_setOnChoicesReorderCallback]
  );

  if (isLoading) {
    return <Spinner text={t("builder.loadingTemplate")} />;
  }
  if (!template) {
    return <Redirect to={urls.backOffice.templateBuilder()} />;
  }

  return (
    <MultiLanguageProvider initialLanguage={company.protocolFillerLanguage}>
      <Provider
        value={{
          reorderQuestion,
          reorderSignaturePage,
          createSignaturePage,
          removeSignaturePage,
          setOnChoicesReorderCallback,
          isLoadingCustomVehicleFields,
          isLoadingLinkTypes,
          customVehicleFields,
          linkTypes,
          form,
          refreshCustomVehicleFields: fetchAllCustomVehicleFields,
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <BackOfficeLayout
            breadcrumbs={[
              {
                label: t("builder.templates"),
                url: urls.backOffice.templateBuilder(),
              },
            ]}
            pageTitle={t("builder.templateEditor")}
          >
            <BackOfficeLayout.Content>
              <div style={{ maxWidth: 800 }}>
                <CommonForm<Template, { templateId: string }>
                  form={form}
                  saveCallback={save}
                  onIsSavingChange={setIsSaving}
                  initialValues={initialValues}
                  onFieldsChange={() => {
                    setShouldShowExitWarning(true);
                  }}
                  onSuccessfulSave={(
                    formValues: Template,
                    savedTemplate: { templateId: string },
                    shouldRedirect: boolean | undefined
                  ) => {
                    if (shouldRedirect) {
                      history.push(urls.backOffice.templateBuilder());
                    } else if (
                      savedTemplate.templateId &&
                      templateId !== savedTemplate.templateId
                    ) {
                      history.replace(
                        urls.backOffice.templateBuilderEdit(
                          savedTemplate.templateId
                        )
                      );
                    }
                  }}
                  confirmationText={t("builder.confirmations.template.save")}
                >
                  {!!initialValues.id && !initialValues.isActive && (
                    <Alert
                      type="warning"
                      message={t("builder.thisTemplateHasBeenRemoved")}
                      style={{ marginBottom: 20 }}
                    />
                  )}
                  <TemplateEditor />
                  <StickySaveButtons isSaving={isSaving} form={form} />
                </CommonForm>
              </div>
            </BackOfficeLayout.Content>
          </BackOfficeLayout>
        </DragDropContext>
      </Provider>

      <Prompt
        when={(() => {
          if (shouldShowExitWarning) {
            enableReloadPageConfirmation();
            return true;
          } else {
            disableReloadPageConfirmation();
            return false;
          }
        })()}
        message={tTranslation("quittingWithUnsavedChangesConfirmation")}
      />
    </MultiLanguageProvider>
  );
}
